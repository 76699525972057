<template>
  <AdminPageFrame class="w-main-container">
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:DiscountCodeEdit') : trans('PAGE_TITLE:DiscountCodeAdd') }}
    </PageTitle>
    <t-card class="w-full">
      <FieldWrapper class="mb-3" :label="trans('LABEL:Code')">
        <TextField v-model="discountCode.code">

        </TextField>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Partner')">
        <SelectField v-model="discountCode.partner" :options="partnersSelectData" :placeholder="''">

        </SelectField>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Percent')">
        <TextField rules="required" v-model="discountCode.percentDays">

        </TextField>
      </FieldWrapper>
      <div class="flex flex-row space-x-3">
        <FieldWrapper class="mb-3" :label="trans('LABEL:ValidFrom')">
          <t-datepicker
              dateFormat="Y-m-d H:i"
              userFormat="d F Y H:i"
              :weekStart="1"
              :lang="langLocale"
              :timepicker="true"
              v-model="discountCode.validFrom">
          </t-datepicker>
        </FieldWrapper>
        <FieldWrapper class="mb-3" :label="trans('LABEL:ValidTo')">
          <t-datepicker
              dateFormat="Y-m-d H:i"
              userFormat="d F Y H:i"
              :weekStart="1"
              :lang="langLocale"
              :timepicker="true"
              v-model="discountCode.validTo">
          </t-datepicker>
        </FieldWrapper>
      </div>
      <FieldWrapper class="mb-3" :label="trans('LABEL:Limit')">
        <TextField rules="required" v-model="discountCode.limit">

        </TextField>
      </FieldWrapper>
      <div class="flex flex-row justify-end">
        <Button :text="trans('BUTTON:Save')" :loading="discountCodesSaving" @clicked="storeDiscountCodeAction">
        </Button>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SelectField from "@/utils/crud/components/field-types/Select";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnerShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    SelectField,
    TextField,
  },
  data() {
    return {
      discountCode: {},

    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchShowDiscountCodeRequest(val);
        }
      }
    },
    discountCodeLoaded(val) {
      if(val) {
        this.discountCode = structuredClone(this.discountCodeData)
      }
    }
  },
  computed: {
    ...mapGetters('discountCodes', ['discountCodesSaving', 'discountCodeLoaded', 'discountCodeData']),
    ...mapGetters('partners', ['partnersSelectData']),
    ...mapGetters('lang', ['langLocale'])
  },
  methods: {
    ...mapActions('discountCodes', ['storeDiscountCodeData', 'fetchShowDiscountCodeRequest', 'updateDiscountCodeData']),
    ...mapActions('partners', ['fetchPartnersSelectRequest']),
    storeDiscountCodeAction() {
      if(this.$route.params.id) {
        this.updateDiscountCodeData(this.discountCode);
        return;
      }
      this.storeDiscountCodeData(this.discountCode);
    }
  },
  mounted() {
    this.fetchPartnersSelectRequest();
  }
}
</script>
